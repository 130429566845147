.bio-content {
  a {
    position: relative;
    display: inline-block;
    transition: 0.3s;
    text-decoration: none;
    color: #1d4ed8;
  }
  a::after {
    position: absolute;
    bottom: 0.3em;
    left: 0;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #1d4ed8;
    opacity: 0;
    transition: 0.3s;
  }
  a:hover::after {
    bottom: 0;
    opacity: 1;
  }
  a:hover {
    color: #1e40af;
  }
}
