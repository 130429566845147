@import "~video-react/styles/scss/video-react";

.home-page {

  h2 {
    font-size: 1.5em;
  }

  section {
    margin: 3em auto;

    &:first-child {
      margin-top: 0;
    }

    &.centered {
      text-align: center;
    }
  }

  form {
    margin-bottom: 1em;

    input {
      outline: none;
      border-bottom: 1px dotted #999;
    }
  }

  span.at-prefix {
    color: black;
    margin-left: 0.4em;
  }
}

tr {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  p, h4 {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    font-size: 16px !important;
  }

  td {
    font-size: 16px !important;
  }
}


.pulse {
    background: #cca92c;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(204,169,44, 0.4);
    animation: pulse 2s infinite;
    z-index: 1501;
  }
  .pulse:hover {
    animation: none;
  }

  @-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(204,169,44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(204,169,44, 0);
        box-shadow: 0 0 0 15px rgba(204,169,44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }


  .pulse-hover {
    cursor: pointer;
    box-shadow: 0 0 0 rgba(204,169,44, 0.4);
    animation: pulse 3s infinite;
  }

  @-webkit-keyframes pulse-hover {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(204,169,44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
  @keyframes pulse-hover {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(204,169,44, 0);
        box-shadow: 0 0 0 15px rgba(204,169,44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }

  .pulse-edit {
    cursor: pointer;
    box-shadow: 0 0 0 rgba(0, 121, 193, 0.4);
    animation: pulse 3s infinite;
  }

  @-webkit-keyframes pulse-edit {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 122, 193, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(0, 121, 193, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 121, 193, 0);
    }
  }
  @keyframes pulse-edit {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(0, 121, 193, 0.4);;
      box-shadow: 0 0 0 0 rgba(0, 121, 193, 0.4);;
    }
    70% {
        -moz-box-shadow: 0 0 0 15px rgba(0, 121, 193, 0);;
        box-shadow: 0 0 0 15px rgba(0, 121, 193, 0);;
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 121, 193, 0);;
        box-shadow: 0 0 0 0 rgba(0, 121, 193, 0);;
    }
  }
