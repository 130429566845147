.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  // border: 1px solid rgb(160, 160, 150);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  padding: 50px;

  @media only screen and (max-width: 600px) {
  }

  &--doubleView {
    width: 700px;

    .react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;

      > * {
        width: 50%;
        margin: 0.5em;
      }
    }
  }

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  button {
    margin: 0;
    border: 0;
    outline: none;

    &:enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__navigation {
    height: 44px;
    margin-bottom: 1em;

    button {
      min-width: 44px;
      background: none;
      font-size: 1.25em;

      &:enabled {
        &:hover,
        &:focus {
          background-color: rgb(230, 230, 230);
        }
      }

      &[disabled] {
        background-color: rgb(240, 240, 240);
      }
    }
  }

  &__month-view {
    &__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.15em;

      &__weekday {
        padding: 0.5em;
      }
    }

    &__weekNumbers {
      font-weight: bold;

      .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.15em;
        padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
      }
    }

    &__days {
      &__day {
        &--weekend {
          color: rgb(209, 0, 0);
        }

        &--neighboringMonth {
          color: rgb(117, 117, 117);
        }
      }
    }
  }

  &__year-view,
  &__decade-view,
  &__century-view {
    .react-calendar__tile {
      padding: 2em 0.5em;
    }

    &__months__month {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
    height: 50px;
    border-radius: 3px;
    font-size: 1.15em;
    // margin: 2px;

    &:disabled {
      background-color: rgb(240, 240, 240);
      cursor: default;
      border-radius: 0;
    }

    &:enabled {
      &:hover,
      &:focus {
        background-color: rgb(230, 230, 230);
      }
    }

    &--now {
      $bgcolor: rgb(0, 110, 220);
      border: 1px solid $bgcolor !important;
      color: $bgcolor;
      font-weight: bold;

      // &:enabled {
      //   &:hover, &:focus {
      //     background: lighten($bgcolor, 10%);
      //   }
      // }
    }

    &--hasActive {
      $bgcolor: lighten(rgb(0, 110, 220), 30%);
      background: $bgcolor;

      &:enabled {
        &:hover,
        &:focus {
          background: lighten($bgcolor, 10%);
        }
      }
    }

    &--active {
      $bgcolor: rgb(0, 110, 220);
      background: $bgcolor;
      color: white;

      &:enabled {
        &:hover,
        &:focus {
          background: lighten($bgcolor, 10%);
        }
      }
    }
  }

  &--selectRange {
    .react-calendar__tile {
      &--hover {
        background-color: rgb(230, 230, 230);
      }
    }
  }
}
